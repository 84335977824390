<template>
    <div class="login-wrap">
        <div class="title">元诺律师管理平台</div>
        <div class="login">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
                <el-form-item prop="name">
                    <el-input v-model="ruleForm.name" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" v-model="ruleForm.password" prefix-icon="el-icon-goods" placeholder="请输入登录密码"></el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="loginBtnClick" :loading="loading">登录</el-button>
                </div>
                <p style="font-size:12px;line-height:30px;color:#999;">Tips : 忘记密码，联系管理员。</p>
            </el-form>
        </div>
    </div>
</template>
<script>
    import {
        login as login,
    } from "@/api/api";
    const code = "200";
    export default {
        data(){
            return {
                ruleForm: {
                    name: '',
                    password: ''
                },
                loading: false,
                rules:{
                    name: [{required: true, message: '请输入用户名', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}]
                }
            }
        },
        methods: {
            loginBtnClick(){
                const that = this;
                this.$refs["ruleForm"].validate((valid) => {
                    if(valid){
                        this.loading = true
                        login(this.ruleForm).then(res =>{
                            that.loading = false
                            if(res.data.code == code){
                                this.$message({
                                    message: "登录成功",
                                    type: "success"
                                });
                                localStorage.setItem('token',res.data.data.token);
                                localStorage.setItem('name',res.data.data.name);
                                that.$router.push('/index');
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>
<style scoped>
.login-wrap{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(50, 65, 87)
}
.title{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    color: white;
    margin-top: -230px;
    font-size: 30px;
}
.login{
    position: absolute;
    left:50%;
    top:50%;
    width:300px;
    height:200px;
    margin:-150px 0 0 -190px;
    padding:40px;
    border-radius: 5px;
    background: #fff;
}
.login-btn {
  text-align: center;
  margin-top: 40px;
}
.login-btn button {
  /* margin-left: 70px; */
  width: 300px;
  height: 2.9rem;
  background-color: #409eff;
  box-shadow: -2px 1px 9px 0px rgba(7, 0, 2, 0.17);
  font-size: 1.1rem;
}
</style>